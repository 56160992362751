export const COLORS = {
  PRIMARY_COLOR: '#f44336',
  SECONDARY_COLOR: '#b54a42',
  RED: '#e53935',
  GREY: '#a0a6a9',
  DARK_GREY: '#434343',
  WHITE: '#FFFFFF',
  DARK_BLUE: '#2d323e',
  DARKED_BLUE: '#1e2129',
  LIGHT_GREY: '#d3d3d3',
  VERY_LIGHT_GREY: '#E5E5E5',
  GRAY: '#969090',
  BLACK: '#000000',
  BACKGROUND_GREY: '#FCFCFC',
  BACKGROUND_PACKAGE_TYPE: '#7373731a',
  ERROR: '#f44336'
};
